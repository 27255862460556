@import url("https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap");
.container {
  max-width: 1050px;
  width: 90%;
  margin: 0 auto;
}

.checkbox {
  display: none;
}

.navbar {
  font-family: "Pathway Gothic One", sans-serif;
  width: 100%;
  box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
  position: fixed;
  top: 0;
  background: #262626;
  z-index: 1;
}
.navbar h1 {
  font-size: 2rem;
}
.menu-items {
  font-size: 1.5rem;
}
.nav-container {
  width: 100%;
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 62px;
}

.menu-items {
  display: flex;
}

.navbar .nav-container a {
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.7rem;
}

.navbar .nav-container li {
  list-style: none;
}
.logo {
  width: 75%;
  color: white;
}
.navbar .nav-container a:hover {
  font-weight: bolder;
}

@media (max-width: 900px) {
  .container {
    max-width: 1050px;
    width: 90%;
    margin: auto;
  }
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
  }

  .navbar .menu-items {
    display: flex;
  }

  .navbar .nav-container li {
    list-style: none;
  }

  .navbar .nav-container a {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0.7rem;
  }

  .navbar .nav-container a:hover {
    font-weight: bolder;
  }

  .nav-container {
    display: block;
    position: relative;
    height: 60px;
  }

  .nav-container .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .nav-container .hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 17px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: white;
  }

  .nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }
  .navbar .menu-items {
    padding-top: 120px;
    background: #262626;
    box-shadow: inset 0 0 2000px black;
    height: 100vh;
    width: 100%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    padding-left: 50px;
    transition: transform 0.5s ease-in-out;
    text-align: center;
  }

  .navbar .menu-items li {
    margin-bottom: 1.2rem;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .logo {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 1rem;
    color: white;
  }

  .nav-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }

  .nav-container input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
}
@media (max-width: 450px) {
  .logo h1 {
    font-size: larger;
  }
}
