.contact-me__section {
  padding-top: 20px;
}
.link-section {
  color: white;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-section {
  width: 50%;
}
.one-line-input + .one-line-input {
  margin-top: 1rem;
}
input {
  font-family: "poppins", sans-serif;
  border: 1px solid black;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 10px;
  background-color: transparent;
  color: white;
}
textarea {
  resize: none;
  font-family: "poppins", sans-serif;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  height: 15em;
  background-color: transparent;
  border: 1px solid black;
  color: white;
}
.one-line-input {
  height: 2em;
}
.contact-btn {
  width: 50%;
  border-radius: 10px;
  border: none;
  padding: 0.5em;
  background: green;
  color: white;
  font-family: "poppins", sans-serif;
}
@media (max-width: 1000px) {
  .email-section {
    width: 100%;
  }
  .link-section {
    width: 100%;
    padding: 20px;
  }
}
